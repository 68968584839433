<template>
	<div class="about">
		<div class="about-content">
			<Topq></Topq>
			<div class="about-main">
				<div class="about-main-content">
					<span>关于我们</span>
					<!-- <div>{{about.introduce}}</div> -->
					<!-- <div>四川谷丰旺成网络科技有限公司，面向大型公司和快速发展的中小企业提供一站式专业人力资源服务，为企业提供网络招聘、校 园招聘、猎头等服务，为求职者提供更新更全的招聘信息，快速拿到心仪的offer。</div> -->
					<!-- <div>成都迅牛网络科技有限公司，面向大型公司和快速发展的中小企业提供一站式专业人力资源服务，为企业提供网络招聘、校 园招聘、猎头等服务，为求职者提供更新更全的招聘信息，快速拿到心仪的offer。</div> -->
					<div>成都滔滔信息技术有限公司，面向大型公司和快速发展的中小企业提供一站式专业人力资源服务，为企业提供网络招聘、校 园招聘、猎头等服务，为求职者提供更新更全的招聘信息，快速拿到心仪的offer。</div>
				</div>
			</div>
			<div class="bai">
				<div class="bai-item">
					<img src="../assets/image/di.png">
					<!-- <span>{{about.address}}</span> -->
					<!-- <span>中国（四川）自由贸易试验区成都市天府新区万安街道麓山大道二段1201号20栋19层1926号</span> -->
					<!-- <span>重庆市渝北区龙溪街道新溉大道18号国宾城11幢2-6</span> -->
					<span>四川省成都市天府新区万安街道万福寺路816号9栋5层511号</span>
				</div>
				<div class="bai-item">
					<img src="../assets/image/lujing.png">
					<span>18810752898</span>
				</div>
				<div class="bai-item">
					<img src="../assets/image/you.png">
					<!-- <span>{{about.email}}</span> -->
					<!-- <span>gufeng1121@163.com</span> -->
					<!-- 加贝科技 -->
					<!-- <span>2379418446@qq.com</span> --> 
					<!-- 滔滔 -->
					<span>chentaotao@taotaotec.com</span>
				</div>
			</div>
		</div>
		<Footer></Footer>
	</div>
</template>

<script>
	import Topq from './common/Topq.vue'
	import $http from '../common/api/axios.js'
	import {
		webInfo
	} from '../common/api/api.js'

	import Footer from '../components/common/Footer.vue'
	export default {
		data() {
			return {
				about: {},
				imgLink: require('../assets/img/about.jpg')
			}
		},
		components: {
			Topq,
			Footer
		},
		created() {
			let that = this
			this.$store.dispatch('initDic').then(() => {
				$http.request({
					url: webInfo,
				}).then((res) => {
					that.about = res.data
				}).catch(() => {

				});

			})
		}
	}
</script>

<style scoped>
	.about-main-content>div {
		padding-top: .1rem;
		font-family: PingFang SC;
		font-size: .16rem;
		font-weight: 500;
		line-height: .35rem;
		color: #333333;
	}

	.about-main-content>span {
		font-size: .18rem;
		font-weight: bold;
		color: #333333;
	}

	.about-main-content {
		padding-top: .44rem;
		padding-left: .77rem;
		padding-right: .6rem;
		box-sizing: border-box;
		width: 5.75rem;
		height: 4rem;
		background: #FFFFFF;
		opacity: 0.88;
		border-radius: 6px;
	}

	.about-main {
		display: flex;
		justify-content: center;
		padding-top: 1.5rem;
	}

	.title-right-login {
		display: flex;
		align-items: center;
	}

	.title-right-login img {
		width: .39rem;
	}

	.title-right-login span {
		margin-left: .12rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: 400;
		color: #333333;
	}

	.title-right {
		margin-left: .65rem;
	}

	.title-left span {
		margin-left: .64rem;
	}

	.tabItem {
		padding: 10px 0;
		font-size: .16rem;
		font-family: PingFang SC;
		font-weight: 600;
		color: #333333;
		text-decoration: none
	}

	.router-link-active {
		font-size: .18rem;
		font-family: PingFang SC;
		font-weight: bold;
		color: #E41D1D;
		border-bottom: .04rem solid #E41D1D;
	}


	.title {
		display: flex;
		align-items: center;
		width: 100%;
		height: .72rem;
		z-index: 999;
		font-size: .16rem;
		font-family: PingFang SC;
		color: #FFFFFF;
		cursor: pointer;
	}

	.title-logo {
		margin-left: 3.6rem;
		margin-right: 4.24rem;
	}

	.title-logo img {
		width: 1.5rem;
	}

	.bai-item span {
		margin-left: .1rem;
		font-size: .14rem;
		font-family: PingFang SC;
		font-weight: 600;
		color: #333333;
	}

	.bai-item img {
		width: .18rem;
	}

	.bai-item {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.about-content {
		position: relative;
		width: 19.2rem;
		height: 8.78rem;
		background: url(../assets/image/bg2.png) no-repeat;
		background-size: 100%;
	}

	.bai {
		box-sizing: border-box;
		padding: 0 1.5rem;
		display: flex;
		justify-content: space-around;
		position: absolute;
		bottom: 0;
		width: 19.2rem;
		height: .77rem;
		background: #FFFFFF;
		opacity: 0.8;
	}
</style>